import React from 'react';
import Cookies from 'js-cookie';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { hcSecDialogData } from '@hcl-code/hcl-code-data-sample/data/hcData';
import DomPurify from 'dompurify';

// MUI styles
const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiPaper-rounded': {
            borderRadius: 0
        }
    },
    typography1: {
        fontSize: theme.spacing(1.75),
        color: '#000000'
    }
}));

const IhSecurityDialog = ({ isSecNoticeAccepted, setSecNoticeAccepted }) => {
    const classes = useStyles();
    const handleAccept = e => {
        if (e.currentTarget.id === 'btn-accept') {
            Cookies.set('ih_secn_acpt', true, {
                secure: process.env.NODE_ENV !== 'development',
                sameSite: 'strict'
            });
            setSecNoticeAccepted(true);
        }
    };

    return (
        <Dialog
            open={!isSecNoticeAccepted}
            aria-labelledby="ih-sec-dialog-title"
            aria-describedby="ih-sec-dialog-description"
            className={classes.root}
        >
            <DialogTitle id="ih-sec-dialog-title">
                {hcSecDialogData.title}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography
                    variant="subtitle2"
                    className={classes.typography1}
                    gutterBottom
                >
                    HCL Innovation Hub users must accept the{' '}
                    <Link
                        href={DomPurify.sanitize(hcSecDialogData.linkUrl)}
                        underline="always"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {hcSecDialogData.linkText}
                    </Link>{' '}
                    and the following information security notice to proceed:
                </Typography>
                <Typography
                    variant="subtitle2"
                    className={classes.typography2}
                    gutterBottom
                >
                    {hcSecDialogData.desc}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button id="btn-accept" onClick={handleAccept} color="primary">
                    Accept
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default IhSecurityDialog;
