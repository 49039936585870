import React, { useState, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Avatar from '@material-ui/core/Avatar';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PaletteIcon from '@material-ui/icons/Palette';
import StarsIcon from '@material-ui/icons/Stars';
import ReportIcon from '@material-ui/icons/Report';
import SettingsIcon from '@material-ui/icons/Settings';
import ExploreIcon from '@material-ui/icons/Explore';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import { userMaleDP } from '@hcl-code/hcl-code-data-sample/data/hcImgDataURI';
import { getDisplayName } from '@hcl-code/hcl-code-helpers/js/helpers';
import { hcTeamMembers } from '@hcl-code/hcl-code-data-sample/data/hcData';

// MUI styles
const useStyles = makeStyles(theme => ({
    ihTeamDialog: {
        padding: theme.spacing(2),
        '& .MuiDialog-paper': {
            maxWidth: '75vw',
            borderRadius: 0,
            '@media only screen and (max-width: 585px)': {
                maxWidth: '100vw',
                margin: 0
            }
        },
        '& .MuiDialogActions-root': {
            paddingBottom: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3)
        }
    },
    ihTeamDialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
        textAlign: 'center',
        '&:first-child': {
            textTransform: 'uppercase'
        }
    },
    ihTeamDialogContent: {
        display: 'flex',
        overflowY: 'unset',
        padding: '26px 32px 32px',
        '&.MuiDialogContent-root:first-child': {
            paddingTop: '26px'
        },
        '@media only screen and (max-width: 585px)': {
            padding: '16px !important'
        }
    },
    ihNoBorderRadius: {
        borderRadius: 0
    },
    ihTeamCardContainer: {
        display: 'flex'
    },
    ihTeamCard: {
        flexGrow: 1,
        border: '1px solid #ddd'
    },
    ihTeamCardContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    ihTeamCardActionArea: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 200,
        width: '100%',
        paddingTop: theme.spacing(2),
        '& > .MuiBox-root:first-child': {
            height: '5rem',
            width: '5rem',
            fontSize: '1.875rem',
            '& img': {
                height: '5rem',
                width: '5rem'
            }
        }
    },
    ihUserDPName: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#a7bff5c9',
        color: '#262626',
        border: '1px solid #a7bff5c9',
        borderRadius: '50%',
        overflow: 'hidden',
        textTransform: 'uppercase',
        '& img': {
            height: '1.875rem',
            width: '1.875rem'
        }
    },
    ihUserDPSize: {
        height: '5rem',
        width: '5rem',
        border: '1px solid #7986cb',
        '& > img': {
            marginLeft: 0
        }
    },
    ihCloseButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
}));
const KEY_DELAY = 1000;
let enteredKeys = [];
let lastKeyTime = Date.now();

const DialogTitle = props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={classes.ihTeamDialogTitle}
            {...other}
        >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.ihCloseButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const IhOurTeam = props => {
    const classes = useStyles();
    const [showTeam, setShowTeam] = useState(false);
    const [hcTeamMembersRoleIcons] = useState([
        <PaletteIcon />,
        <StarsIcon />,
        <ExploreIcon />,
        <SportsSoccerIcon />,
        <ReportIcon />,
        <PaletteIcon />,
        <AccountCircleIcon />,
        <SettingsIcon />
    ]);

    const handleModalClose = e => {
        setShowTeam(false);
    };

    window.addEventListener('keyup', e => {
        e = e || window.event;
        const key = e.keyCode;
        const currentTime = Date.now();
        if (currentTime - lastKeyTime > KEY_DELAY) {
            enteredKeys = [];
        }
        enteredKeys.push(key);
        lastKeyTime = currentTime;
        if (`${[...new Set(enteredKeys)].join('')}` === '67486869') {
            setShowTeam(true);
        }
    });

    return (
        <Dialog
            open={showTeam}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleModalClose}
            aria-describedby="ih-building-team"
            className={classes.ihTeamDialog}
        >
            <DialogTitle
                id="ih-building-team-title"
                classes={classes}
                onClose={handleModalClose}
            >
                Innovation Hub Team
            </DialogTitle>
            <DialogContent className={classes.ihTeamDialogContent} dividers>
                <Grid container spacing={3}>
                    {hcTeamMembers.map((teamM, i) => (
                        <Grid
                            item
                            xs={12}
                            md={3}
                            key={i}
                            component="div"
                            className={classes.ihTeamCardContainer}
                        >
                            <Card
                                className={`${classes.ihTeamCard} ${classes.ihNoBorderRadius}`}
                            >
                                <CardActionArea
                                    className={classes.ihTeamCardActionArea}
                                >
                                    <Box
                                        className={
                                            !teamM.dp
                                                ? classes.ihUserDPName
                                                : ''
                                        }
                                    >
                                        {teamM.dp ? (
                                            <Avatar
                                                alt="User"
                                                src={teamM.dp || userMaleDP}
                                                className={classes.ihUserDPSize}
                                            />
                                        ) : (
                                            getDisplayName(teamM.name)
                                        )}
                                    </Box>
                                    <CardContent
                                        className={classes.ihTeamCardContent}
                                    >
                                        <Typography
                                            gutterBottom
                                            variant="subtitle2"
                                            component="p"
                                        >
                                            {teamM.name}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                            component="p"
                                        >
                                            {teamM.email}
                                        </Typography>
                                        <Chip
                                            size="small"
                                            icon={hcTeamMembersRoleIcons[i]}
                                            label={teamM.role}
                                            clickable
                                            color="primary"
                                            variant="outlined"
                                            style={{ marginTop: 10 }}
                                        />
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default IhOurTeam;
