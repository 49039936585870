import React, { useState, useEffect, useContext, Fragment } from 'react';
import { withRouter, useLocation, Link } from 'react-router-dom';
import uuid from 'uuid';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import Cookies from 'js-cookie';
import { IhContext } from '../../contexts/IhContext';
import { userMaleDP } from '@hcl-code/hcl-code-data-sample/data/hcImgDataURI';
import InnovationHubLogoPng from '@hcl-code/hcl-code-data-sample/images/hclsw-ihub-logo.png';
import {
    getDisplayName,
    logoutAndRedirect
} from '@hcl-code/hcl-code-helpers/js/helpers';
import IhInfoSecNotice from '../IhInfoSecNotice';
const ihVersion = 'Innovation Hub v1.0.0';
const drawerWidth = 260;
const appBarMinHeight = 64;
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        [`& fieldset`]: {
            borderRadius: 0
        }
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: 0
        }
    },
    toolbar: theme.mixins.toolbar,
    toolbarTop: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        padding: '1.5rem 0.5rem',
        '& img': {
            [theme.breakpoints.down('sm')]: {
                marginLeft: '3rem'
            }
        }
    },
    drawerPaper: {
        width: drawerWidth
    },
    search: {
        position: 'relative',
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        borderRadius: 0,
        width: '100%',
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25)
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(2),
            width: 'auto'
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit'
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // Vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch'
            }
        }
    },
    ihLogoContainer: {
        height: '100%'
    },
    ihLogo: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
        '& > img': {
            height: theme.spacing(3.75),
            width: theme.spacing(38),
            [theme.breakpoints.down('xs')]: {
                width: theme.spacing(40.5)
            }
        }
    },
    ihDesktopAppbarNavItems: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    ihDesktopAppbarNavItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap',
        minHeight: appBarMinHeight,
        fontSize: theme.spacing(2),
        textTransform: 'capitalize',
        textDecoration: 'none',
        color: '#fff',
        outline: 'none',
        whiteSpace: 'nowrap',
        '&:hover': {
            backgroundColor: 'rgba(30, 136, 229, 0.6)'
        },
        '&.active': {
            backgroundColor: blue[600],
            boxShadow: '0 3px 0 0 #000'
        }
    },
    ihAppbarNavBtn: {
        padding: '0 1.5rem'
    },
    ihDrawerListItemText: {
        '& a': {
            justifyContent: 'flex-start',
            minHeight: 0,
            textTransform: 'capitalize',
            color: 'inherit',
            '&:hover': {
                backgroundColor: 'transparent',
                boxShadow: 'none'
            }
        }
    },
    ihDrawerUserContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 200,
        width: '100%',
        color: '#fff',
        '& > .MuiBox-root:first-child': {
            height: '6rem',
            width: '6rem',
            fontSize: '2.5rem',
            '& img': {
                height: '6rem',
                width: '6rem'
            }
        }
    },
    ihUserDPSize: {
        height: theme.spacing(12),
        width: theme.spacing(12),
        border: '1px solid #7986cb',
        '& > img': {
            marginLeft: 0
        }
    },
    ihListItemIconSize: {
        minWidth: theme.spacing(4.5)
    },
    ihNoBorderRadius: {
        '& .MuiPaper-root': {
            borderRadius: 0
        }
    },
    ihDesktopAppbarNavItemBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: appBarMinHeight,
        marginRight: 0
    },
    ihActiveDrawerLink: {
        color: theme.palette.primary.main
    },
    ihUserDPName: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        color: '#262626',
        height: '1.875rem',
        width: '1.875rem',
        borderRadius: '50%',
        fontSize: '0.75rem',
        overflow: 'hidden',
        textTransform: 'uppercase',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#262626'
        },
        '& img': {
            height: '1.875rem',
            width: '1.875rem'
        }
    },
    ihDesktopUserTray: {
        '& .MuiPaper-root': {
            minWidth: '15rem'
        },
        '& .MuiTypography-root': {
            display: 'flex',
            flexDirection: 'column',
            '& > .MuiBox-root': {
                minHeight: '11.25rem',
                width: '100%',
                marginLeft: 0,
                color: '#000000de',
                '& > *:first-child': {
                    backgroundColor: '#a7bff5c9',
                    border: '1px solid #a7bff5c9'
                }
            }
        }
    },
    ihDesktopUserNRContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        minHeight: '64px',
        padding: '0 1.5rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(30, 136, 229, 0.6)'
        }
    },
    ihDesktopUserNR: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '0.625rem'
    },
    ihInfoSecNoticeAppbar: {
        backgroundColor: '#fff4e5'
    },
    t3075: {
        top: '51px',
        backgroundColor: grey[900],
        boxShadow: 'none',
        // Custom breakpoints for `Header`
        '@media only screen and (min-width: 744px) and (max-width: 1083px)': {
            top: '68px'
        },
        '@media only screen and (min-width: 585px) and (max-width: 743px)': {
            top: '85px'
        },
        '@media only screen and (min-width: 473px) and (max-width: 584px)': {
            top: '102px'
        },
        '@media only screen and (min-width: 416px) and (max-width: 472px)': {
            top: '119px'
        },
        '@media only screen and (min-width: 371px) and (max-width: 415px)': {
            top: '136px'
        },
        '@media only screen and (min-width: 333px) and (max-width: 370px)': {
            top: '153px'
        },
        '@media only screen and (max-width: 332px)': {
            top: '170px'
        }
    },
    ihToolbar: {
        paddingRight: 0
    },
    ihDesktopUserTrayHanger: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

let IhHeader = props => {
    const classes = useStyles();
    const theme = useTheme();
    const currPathname = useLocation().pathname;
    const { ihApiUtils, userDetails, ihFeatureFlags } = useContext(IhContext);
    const [activeTab, setActiveTab] = useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const openUserTray = Boolean(anchorEl);

    useEffect(() => {
        getRightActiveTab();
    }, [currPathname]);

    const getRightActiveTab = () => {
        setActiveTab(
            currPathname === '/'
                ? 0
                : currPathname.includes('/dashboard')
                ? 1
                : null
        );
    };
    const handleTabChange = e => {
        setActiveTab(parseInt(e.currentTarget.tabIndex));
        setIsDrawerOpen(false);
    };
    const handleDrawerToggle = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };
    const handleUserTrayMenuClick = e => {
        setAnchorEl(e.currentTarget);
    };
    const handleUserTrayClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        setAnchorEl(null);
        setIsDrawerOpen(false);
        logoutAndRedirect(ihApiUtils, Cookies);
    };
    window.addEventListener('click', () => {
        getRightActiveTab();
    });
    window.addEventListener('resize', () => {
        setIsDrawerOpen(false);
    });
    const drawer = (
        <Box>
            <Box className={`${classes.toolbar} ${classes.toolbarTop}`}>
                {userDetails ? (
                    <Box
                        className={classes.ihDrawerUserContainer}
                        title={userDetails && userDetails.name}
                    >
                        <Box
                            mb={2}
                            className={
                                !userDetails.dp ? classes.ihUserDPName : ''
                            }
                        >
                            {userDetails.dp ? (
                                <Avatar
                                    alt="User"
                                    src={userDetails.dp || userMaleDP}
                                    className={classes.ihUserDPSize}
                                />
                            ) : (
                                getDisplayName(userDetails.name)
                            )}
                        </Box>
                        <Box mb={1} fontSize="1rem">
                            {userDetails.name || ''}
                        </Box>
                        <Box mb={1} fontWeight="fontWeightLight">
                            {userDetails.email}
                        </Box>
                    </Box>
                ) : (
                    <Box
                        className={classes.ihLogoContainer}
                        color="text.primary"
                    >
                        <a href="/" className={classes.ihLogo}>
                            <img
                                src={InnovationHubLogo}
                                alt="Innovation Hub Logo"
                            />
                        </a>
                    </Box>
                )}
            </Box>
            <List>
                <ListItem button>
                    <ListItemIcon className={classes.ihListItemIconSize}>
                        <HomeIcon
                            className={
                                (activeTab === 0 &&
                                    classes.ihActiveDrawerLink) ||
                                ''
                            }
                        />
                    </ListItemIcon>
                    <ListItemText className={classes.ihDrawerListItemText}>
                        <Link
                            to="/"
                            alt="Menu"
                            tabIndex="0"
                            className={classes.ihDesktopAppbarNavItem}
                            onClick={handleTabChange}
                        >
                            Home
                        </Link>
                    </ListItemText>
                </ListItem>
                {ihFeatureFlags && ihFeatureFlags.dashboardMenu && (
                    <ListItem button>
                        <ListItemIcon className={classes.ihListItemIconSize}>
                            <DashboardIcon
                                className={
                                    (activeTab === 1 &&
                                        classes.ihActiveDrawerLink) ||
                                    ''
                                }
                            />
                        </ListItemIcon>
                        <ListItemText className={classes.ihDrawerListItemText}>
                            <Link
                                to={`/dashboard/${uuid.v4()}`}
                                alt="Menu"
                                tabIndex="1"
                                className={classes.ihDesktopAppbarNavItem}
                                onClick={handleTabChange}
                            >
                                Dashboard
                            </Link>
                        </ListItemText>
                    </ListItem>
                )}
            </List>
            {userDetails ? (
                <Fragment>
                    <Divider />
                    <List>
                        <ListItem button onClick={handleLogout}>
                            <ListItemIcon
                                className={classes.ihListItemIconSize}
                            >
                                <ExitToAppIcon color="secondary" />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    </List>
                </Fragment>
            ) : null}
        </Box>
    );
    const container =
        props.window !== undefined ? () => window().document.body : undefined;

    return (
        <header className={classes.root}>
            <AppBar position="fixed" className={classes.ihInfoSecNoticeAppbar}>
                <IhInfoSecNotice />
            </AppBar>
            <AppBar position="fixed" className={classes.t3075}>
                <Toolbar className={classes.ihToolbar}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box className={classes.ihLogoContainer}>
                        <Tooltip title={ihVersion}>
                            <a href="/" className={classes.ihLogo}>
                                <img src={InnovationHubLogoPng} alt="Logo" />
                            </a>
                        </Tooltip>
                    </Box>
                    <Box className={classes.ihDesktopAppbarNavItems}>
                        <Box
                            mr={(!userDetails && 2) || 3}
                            className={classes.ihDesktopAppbarNavItemBox}
                        >
                            <Link
                                to="/"
                                alt="Menu"
                                tabIndex="0"
                                className={`${classes.ihAppbarNavBtn} ${
                                    classes.ihDesktopAppbarNavItem
                                } ${(activeTab === 0 && 'active') || ''}`}
                                onClick={handleTabChange}
                            >
                                Home
                            </Link>
                        </Box>
                        {ihFeatureFlags && ihFeatureFlags.dashboardMenu && (
                            <Box>
                                <Link
                                    to={`/dashboard/${uuid.v4()}`}
                                    alt="Menu"
                                    tabIndex="1"
                                    className={`${classes.ihAppbarNavBtn} ${
                                        classes.ihDesktopAppbarNavItem
                                    } ${(activeTab === 1 && 'active') || ''}`}
                                    onClick={handleTabChange}
                                >
                                    Dashboard
                                </Link>
                            </Box>
                        )}
                        {ihFeatureFlags && ihFeatureFlags.search && (
                            <Box mr={1.25} className={classes.search}>
                                <Box className={classes.searchIcon}>
                                    <SearchIcon />
                                </Box>
                                <InputBase
                                    placeholder="Search…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    disabled
                                />
                            </Box>
                        )}
                        {userDetails && (
                            <Box className={classes.ihDesktopUserNRContainer}>
                                <Box
                                    className={classes.ihDesktopUserTrayHanger}
                                    onClick={handleUserTrayMenuClick}
                                >
                                    <IconButton
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        color="inherit"
                                        title={userDetails && userDetails.name}
                                        className={classes.ihUserDPName}
                                    >
                                        {userDetails.dp ? (
                                            <img
                                                src={userMaleDP}
                                                alt="User DP"
                                            />
                                        ) : (
                                            getDisplayName(userDetails.name)
                                        )}
                                    </IconButton>
                                    <Box className={classes.ihDesktopUserNR}>
                                        <Box
                                            title={userDetails.name}
                                            style={{
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            {userDetails.name &&
                                            userDetails.name.length > 20
                                                ? `${userDetails.name.slice(
                                                      0,
                                                      17
                                                  )}...`
                                                : userDetails.name}
                                        </Box>
                                    </Box>
                                </Box>
                                <Menu
                                    id="menu-appbar"
                                    getContentAnchorEl={null}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                    PaperProps={{
                                        style: {
                                            marginTop: '0.3125rem'
                                        }
                                    }}
                                    open={openUserTray}
                                    className={`${classes.ihNoBorderRadius} ${classes.ihDesktopUserTray}`}
                                    onClose={handleUserTrayClose}
                                >
                                    <MenuItem onClick={handleUserTrayClose}>
                                        <ListItemText
                                            primary={
                                                <Box
                                                    className={
                                                        classes.ihDrawerUserContainer
                                                    }
                                                    title={
                                                        userDetails &&
                                                        userDetails.name
                                                    }
                                                >
                                                    <Box
                                                        mb={2}
                                                        className={
                                                            !userDetails.dp
                                                                ? classes.ihUserDPName
                                                                : ''
                                                        }
                                                    >
                                                        {userDetails.dp ? (
                                                            <Avatar
                                                                alt="User"
                                                                src={
                                                                    userDetails.dp ||
                                                                    userMaleDP
                                                                }
                                                                className={
                                                                    classes.ihUserDPSize
                                                                }
                                                            />
                                                        ) : (
                                                            getDisplayName(
                                                                userDetails.name
                                                            )
                                                        )}
                                                    </Box>
                                                    <Box mb={1} fontSize="1rem">
                                                        {userDetails.name || ''}
                                                    </Box>
                                                    <Box
                                                        mb={1}
                                                        fontWeight="fontWeightLight"
                                                    >
                                                        {userDetails.email}
                                                    </Box>
                                                </Box>
                                            }
                                        />
                                    </MenuItem>
                                    {/* Feature not released
                                     <MenuItem
                                        component={Link}
                                        to={'/new-features'}
                                        onClick={handleUserTrayClose}
                                    >
                                        <ListItemIcon
                                            className={
                                                classes.ihListItemIconSize
                                            }
                                        >
                                            <SettingsIcon color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary="Settings" />
                                    </MenuItem> */}
                                    <MenuItem onClick={handleLogout}>
                                        <ListItemIcon
                                            className={
                                                classes.ihListItemIconSize
                                            }
                                        >
                                            <ExitToAppIcon color="secondary" />
                                        </ListItemIcon>
                                        <ListItemText primary="Logout" />
                                    </MenuItem>
                                </Menu>
                            </Box>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
            <nav
                className={classes.drawer}
                aria-label="HCL Software Innovation Labs nav links"
            >
                <Hidden mdUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={isDrawerOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        ModalProps={{
                            keepMounted: true
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </header>
    );
};

export default withRouter(IhHeader);
