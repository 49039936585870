import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { hcSecDialogData } from '@hcl-code/hcl-code-data-sample/data/hcData';
import DomPurify from 'dompurify';

// MUI styles
const useStyles = makeStyles(theme => ({
    ihInfoSecNoticeAlert: {
        flexGrow: 1,
        borderRadius: theme.spacing(0),
        lineHeight: 1.25,
        '& .MuiAlert-icon': {
            paddingTop: 0
        },
        '& .MuiAlert-message': {
            padding: 0
        }
    }
}));

const IhInfoSecNotice = props => {
    const classes = useStyles();

    return (
        <Alert
            icon={<InfoIcon fontSize="inherit" />}
            variant="outlined"
            severity="warning"
            className={classes.ihInfoSecNoticeAlert}
        >
            <Typography
                component="span"
                style={{ fontWeight: 'bold', lineHeight: 1.25 }}
            >
                Information Security Notice:
            </Typography>
            <Box component="span" ml={0.5}>
                This application may <b>not</b> be used to store U.S. Federal
                Government Customer Data or other content restricted to Approved
                Personnel on the Federal Access Control List. For more
                information, see the{' '}
                <a
                    href="https://hclo365.sharepoint.com/sites/HCL_CFIUS/SitePages/Welcome-to-the-U.S.-National-Security-Compliance-Site.aspx"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    U.S. National Security Compliance site
                </a>
                . Please report any violations to{' '}
                <a
                    href="mailto:cfius@hcl.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    cfius@hcl.com
                </a>
                . Use of HCL employee personal data is subject to the{' '}
                <a
                    href={DomPurify.sanitize(hcSecDialogData.linkUrl)}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Global Employee Privacy Notice
                </a>
                .
            </Box>
        </Alert>
    );
};

export default IhInfoSecNotice;
