import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import LightBlue from '@material-ui/core/colors/lightBlue';
import goldMedal from '@hcl-code/hcl-code-data-sample/images/gold-medal.png';
import ilabsInnovationsWeb from '@hcl-code/hcl-code-data-sample/images/ilabs-innovation-web.png';
import ihubTimeChart from '@hcl-code/hcl-code-data-sample/images/ihub-time-chart.png';
import { IhContext } from '../../contexts/IhContext';
import { getDisplayName } from '@hcl-code/hcl-code-helpers/js/helpers';
import {
    ihPrograms,
    ihUserDetails
} from '@hcl-code/hcl-code-data-sample/data/hcData';

// MUI styles
const useStyles = makeStyles(theme => ({
    ihDevDashContainer: {
        width: '70%',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
        padding: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    ihLeft: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    ihUserInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: theme.spacing(3.5),
        padding: theme.spacing(3, 2),
        backgroundColor: '#fff'
    },
    ihInnovationWeb: {
        height: theme.spacing(25),
        width: theme.spacing(25),
        '& img': {
            height: '100%',
            width: '100%'
        }
    },
    ihInnovationY2Y: {
        height: theme.spacing(20),
        width: '90%',
        marginTop: theme.spacing(1.5),
        '& img': {
            height: '100%',
            width: '100%'
        }
    },
    ihRight: {
        display: 'flex',
        flexDirection: 'column'
    },
    ihPaper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: theme.spacing(27),
        padding: theme.spacing(3, 1.75)
    },
    ihAbout: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(1, 3, 8.75),
        marginBottom: theme.spacing(3)
    },
    ihAboutHeading: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    mt: {
        marginTop: theme.spacing(1)
    },
    mt3: {
        marginTop: theme.spacing(3)
    },
    mb3: {
        marginBottom: theme.spacing(3)
    },
    avatar: {
        height: theme.spacing(25),
        width: theme.spacing(25),
        fontSize: theme.spacing(10),
        color: theme.palette.getContrastText(LightBlue[500]),
        backgroundColor: LightBlue[500]
    },
    ihPoints: {
        color: '#1E88E5'
    },
    ihUnderlinedAppName: {
        textAlign: 'center',
        marginBottom: theme.spacing(3)
    },
    ihUnderline: {
        display: 'inline-flex',
        height: theme.spacing(0.75),
        width: '60%',
        backgroundColor: '#acddf3',
        borderRadius: theme.spacing(2)
    },
    ihAppLogo: {
        height: theme.spacing(7),
        width: theme.spacing(21.25),
        marginBottom: theme.spacing(3),
        '& img': {
            height: '100%',
            width: '100%'
        }
    },
    ihMedal: {
        height: theme.spacing(5.5),
        width: theme.spacing(15),
        marginTop: theme.spacing(3),
        '& img': {
            height: '100%',
            width: '100%'
        }
    },
    ihAppName: {
        fontSize: theme.spacing(2.5)
    }
}));

const IhDashboard = props => {
    const classes = useStyles();
    const { userDetails } = useContext(IhContext);

    return (
        <Box className={classes.ihDevDashContainer}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4} className={classes.ihLeft}>
                    {userDetails ? (
                        <Fragment>
                            <Avatar
                                src={userDetails.dp}
                                alt={userDetails.name}
                                className={classes.avatar}
                            >
                                {getDisplayName(userDetails.name)}
                            </Avatar>
                            <Paper className={classes.ihUserInfo}>
                                <Typography variant="h6">
                                    {userDetails.name}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    {userDetails.email}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                    className={classes.mt3}
                                >
                                    {userDetails.jobTitle}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    {ihUserDetails.team}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    {ihUserDetails.location}
                                </Typography>
                                <Box className={classes.ihMedal}>
                                    <img src={goldMedal} alt="Medal" />
                                </Box>
                                <Typography
                                    variant="h6"
                                    className={classes.mt3}
                                >
                                    Total points accrued
                                </Typography>
                                <Typography
                                    variant="h4"
                                    className={classes.ihPoints}
                                >
                                    1060
                                </Typography>
                                <Typography
                                    variant="h6"
                                    className={classes.mt3}
                                >
                                    Innovation Web
                                </Typography>
                                <Box className={classes.ihInnovationWeb}>
                                    <img
                                        src={ilabsInnovationsWeb}
                                        alt="Innovation web"
                                    />
                                </Box>
                                <Typography
                                    variant="h6"
                                    className={classes.mt3}
                                >
                                    Innovation Y2Y
                                </Typography>
                                <Box className={classes.ihInnovationY2Y}>
                                    <img
                                        src={ihubTimeChart}
                                        alt="Innovation Y2Y Chart"
                                    />
                                </Box>
                                <Typography
                                    variant="body1"
                                    className={classes.mt}
                                >
                                    Total points earned year to year
                                </Typography>
                            </Paper>
                        </Fragment>
                    ) : null}
                </Grid>
                <Grid item xs={12} md={8} className={classes.ihRight}>
                    <Paper className={classes.ihAbout}>
                        <Box className={classes.ihAboutHeading}>
                            <Typography variant="h6">About</Typography>
                            <IconButton aria-label="close">
                                <EditIcon />
                            </IconButton>
                        </Box>
                        <Box className={classes.iLabsAboutText}>
                            A brief statement of the profile. Years of
                            experience, a powerful summary to highlight
                            education, accomplishments and technical skills. A
                            brief statement of the profile. Years of experience,
                            a powerful summary to highlight education,
                            accomplishments and technical skills.
                        </Box>
                    </Paper>
                    <Grid container spacing={3}>
                        {ihPrograms
                            .slice(0, 14)
                            .map(({ name, scores, logo, isUnderlined }, i) => (
                                <Grid item xs={12} sm={4} md={4} key={i}>
                                    <Paper className={classes.ihPaper}>
                                        {!logo ? (
                                            isUnderlined ? (
                                                <Box
                                                    className={
                                                        classes.ihUnderlinedAppName
                                                    }
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        className={
                                                            classes.ihAppName
                                                        }
                                                    >
                                                        {name}
                                                    </Typography>
                                                    <Box
                                                        className={
                                                            classes.ihUnderline
                                                        }
                                                    />
                                                </Box>
                                            ) : (
                                                <Typography
                                                    variant="h6"
                                                    className={`${classes.ihAppName} ${classes.mb3}`}
                                                >
                                                    {name}
                                                </Typography>
                                            )
                                        ) : (
                                            <Box className={classes.ihAppLogo}>
                                                <img
                                                    src={logo}
                                                    alt="App Logo"
                                                />
                                            </Box>
                                        )}
                                        {scores && (
                                            <Typography variant="caption">
                                                {scores.map(
                                                    ({ title, score }, i) => (
                                                        <Box key={i}>
                                                            {title}:{' '}
                                                            <Typography
                                                                variant="button"
                                                                component="span"
                                                            >
                                                                {score}
                                                            </Typography>
                                                        </Box>
                                                    )
                                                )}
                                            </Typography>
                                        )}
                                    </Paper>
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default IhDashboard;
