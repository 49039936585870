import React, { Fragment, createContext, useEffect, useState } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import IhubApiUtils from '@ihub/ihub-swaggergen-utils';
import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    envConfig,
    ihFeatureFlagsData
} from '@hcl-code/hcl-code-data-sample/data/hcData';
import IhSecurityDialog from './../components/IhSecurityDialog';

// API URLs
const apiUrl = envConfig.ihub.api.url;

// Init API util
const ihApiUtils = new IhubApiUtils({
    domain: apiUrl
});
// MUI styles
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        margin: theme.spacing(0),
        padding: theme.spacing(0)
    },
    ihProgress: {
        textAlign: 'center',
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0
    }
}));

// Define main context API
const IhContext = createContext(ihApiUtils);
// Define main provider component
let IhProvider = props => {
    const currentUrl = useLocation().pathname;
    const [appStore, setAppStore] = useState({
        currentUrl,
        isLogin: true,
        userDetails: null,
        isLoading: true,
        ihApiUtils
    });
    const isTokenThere =
        (Cookies.get('ih_acct') && Cookies.get('ih_reft')) || false;
    const ihFeatureFlags = Cookies.get('ih_ffs')
        ? JSON.parse(Cookies.get('ih_ffs'))
        : ihFeatureFlagsData.map(feature => {
              const ff = {};
              ff[feature.name] = false;
              return ff;
          });
    const [securityNoticeAccepted, setSecurityNoticeAccepted] = useState(
        Cookies.get('ih_secn_acpt') ? Cookies.get('ih_secn_acpt') : false
    );

    useEffect(() => {
        if (isTokenThere) {
            let accessToken = Cookies.get('ih_acct');
            let refreshToken = Cookies.get('ih_reft');
            try {
                // Redirect to /newtoken route for new access_token request if expired
                if (isAccessTokenExpired(accessToken)) {
                    getNewToken(refreshToken);
                } else {
                    // Set the token in auth header of util instance
                    ihApiUtils.setApiKey(`Bearer ${accessToken}`);
                    getUserInfo(false, { ihApiUtils });
                }
            } catch (err) {
                console.log(/* Something went wrong while access_token decoding */);
            }
        } else {
            Cookies.remove('ih_secn_acpt');
            if (appStore.isLogin) {
                const AUTH_URL = `${apiUrl}/login?state=${window.location.href}`;
                window.location = AUTH_URL;
                if (isTokenThere) {
                    getUserInfo();
                }
            }
        }
    }, [appStore.isLogin]);

    const getUserInfo = async (
        isLogin = true,
        instanceObj = {},
        // `redirectUrl` is redirection URL after the form submit action
        redirectUrl
    ) => {
        try {
            // Set request header first
            await setAuthHeader();
            const res = await ihApiUtils.getUserinfo();
            res.data.then(resData => {
                setAppStore({
                    ...appStore,
                    ...instanceObj,
                    isLogin,
                    currentUrl: redirectUrl
                        ? redirectUrl
                        : isLogin
                        ? '/'
                        : currentUrl,
                    isLoading: false,
                    userDetails: {
                        ...resData,
                        email: resData
                            ? resData.email.replace('code.onmicrosoft', '')
                            : ''
                    }
                });
                props.history.push(
                    redirectUrl ? redirectUrl : isLogin ? '/' : currentUrl
                );
            });
        } catch (err) {
            console.log(/* Something went wrong while getting user info */);
        }
    };

    const isAccessTokenExpired = accessToken => {
        const nowTimestamp = Math.floor(new Date().getTime() / 1000);
        const {
            payload: { exp }
        } = jwt.decode(accessToken, { complete: true });
        // Returns `true` if token is expired, else `false`
        return exp < nowTimestamp;
    };

    const getNewToken = async (
        refreshToken,
        cb,
        isSubmit = false,
        redirectUrl
    ) => {
        try {
            const res = await ihApiUtils.postNewtoken({
                payload: { refreshToken }
            });
            res.data.then(async resData => {
                // Overwrite cookie values with new tokens
                Cookies.set('ih_acct', resData.access_token, {
                    secure: process.env.NODE_ENV !== 'development',
                    sameSite: 'strict'
                });
                Cookies.set('ih_reft', resData.refresh_token, {
                    secure: process.env.NODE_ENV !== 'development',
                    sameSite: 'strict'
                });
                // Reset the token in auth header of util instance
                appStore.ihApiUtils.setApiKey(
                    `Bearer ${Cookies.get('ih_acct')}`
                );
                // Stop the loading icon
                setAppStore({ ...appStore, isLogin: false, isLoading: false });
                // Execute the callback method; Coming from various components as part of `setAuthHeader`
                if (cb) {
                    // Execute RESTful API requests callback
                    await cb();
                    // For submit actions, call `getUserInfo` again to sync the states with `userinfo` data
                    // `redirectUrl` is redirection URL after the form submit action
                    if (isSubmit) {
                        getUserInfo(false, { ihApiUtils }, redirectUrl);
                    }
                }
            });
        } catch (err) {
            console.log(/* Something went wrong while getting refresh_token */);
        }
    };

    const setAuthHeader = async (cb, isSubmit = false, redirectUrl) => {
        const accessToken = Cookies.get('ih_acct');
        const refreshToken = Cookies.get('ih_reft');
        if (accessToken && refreshToken) {
            // Redirect to /newtoken route for new access_token request if expired
            if (isAccessTokenExpired(accessToken)) {
                // `cb` is API call callback method coming from various components
                getNewToken(refreshToken, cb, isSubmit, redirectUrl);
            } else {
                // Set the token in auth header of util instance
                appStore.ihApiUtils.setApiKey(`Bearer ${accessToken}`);
                // Execute the callback method; Coming from various components as part of `setAuthHeader`
                if (cb) {
                    // Execute RESTful API requests callback
                    await cb();
                    // For submit actions, call `getUserInfo` again to sync the states with `userinfo` data
                    // `redirectUrl` is redirection URL after the form submit action
                    if (isSubmit) {
                        getUserInfo(false, { ihApiUtils }, redirectUrl);
                    }
                }
            }
        } else {
            console.log(/* Something went wrong with access_token or refresh_token */);
            // OR, maybe user has manually cleared the session data from browser
            // OR, user has logged out of the application (yet to implement this feature)
            // So, in those cases, we need to request for a new token by requesting them to login AGAIN...!
            // To do that, we just need to refresh the page
            window.location.reload();
            return false;
        }
    };

    const classes = useStyles();
    const { isLoading, userDetails } = appStore;

    return (
        <Box className={isLoading ? classes.root : ''}>
            <IhContext.Provider
                value={{ ...appStore, setAuthHeader, ihFeatureFlags }}
            >
                {!isLoading && securityNoticeAccepted
                    ? userDetails && props.children
                    : userDetails && (
                          <Fragment>
                              <LinearProgress className={classes.ihProgress} />
                              <IhSecurityDialog
                                  isSecNoticeAccepted={securityNoticeAccepted}
                                  setSecNoticeAccepted={
                                      setSecurityNoticeAccepted
                                  }
                              />
                          </Fragment>
                      )}
            </IhContext.Provider>
        </Box>
    );
};

IhProvider = withRouter(IhProvider);

export { IhContext, IhProvider };
