import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { IhProvider } from '../../contexts/IhContext';
import IhHeader from '../IhHeader';
import IhFooter from '../IhFooter';
import IhNotFound from '../IhNotFound';
import IhHome from '../IhHome';
import IhDashboard from '../IhDashboard';
import IhFeatureFlagsControl from '../IhFeatureFlagsControl';
import './App.scss';

const App = props => (
    <Router>
        <IhProvider>
            <CssBaseline />
            <IhHeader />
            <main className="ih-main">
                <div className="hcl-row">
                    <div className="hcl-col">
                        <Switch>
                            <Route exact path="/" component={IhHome} />
                            <Route
                                exact
                                path="/dashboard/:profid?"
                                component={IhDashboard}
                            />
                            <Route
                                exact
                                path="/new-features"
                                component={IhFeatureFlagsControl}
                            />
                            <Route component={IhNotFound} />
                        </Switch>
                    </div>
                </div>
            </main>
            <IhFooter />
        </IhProvider>
    </Router>
);

export default App;
