import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Cookies from 'js-cookie';
import { HowItWorksSkeleton } from '../IhSkeletons';
import { ihFeatureFlagsData } from '@hcl-code/hcl-code-data-sample/data/hcData';
import IhLineWithInnerText from '../IhLineWithInnerText';
import blue from '@material-ui/core/colors/blue';

// MUI styles
const useStyles = makeStyles(theme => ({
    root: {
        width: '60%',
        padding: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            padding: '1rem 0',
            '& .MuiGrid-item': {
                paddingTop: theme.spacing(3)
            }
        },
        marginBottom: theme.spacing(4)
    },
    ihUpcomingFeatGridItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'calc(100vh - 248px)'
    },
    ihUpcomingFeatTitle: {
        alignSelf: 'flex-start',
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },

    ihUpcomingFeatPaper: {
        flex: 1,
        width: '100%',
        display: 'flex',
        padding: theme.spacing(4),
        paddingLeft: theme.spacing(5.5),
        paddingRight: theme.spacing(5.5),
        marginBottom: theme.spacing(2),
        border: '1px solid #eeeeee',
        '&.data-loading': {
            flex: 1,
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    ihUpcomingFeatRow: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& .MuiGrid-container': {
            justifyContent: 'flex-end',
            paddingRight: theme.spacing(3.5),
            flexBasis: '40%'
        }
    },
    ihNoBorderRadius: {
        borderRadius: 0
    },
    ihUpcomingFeatIntro: {
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    ihContainer: {
        width: '100%',
        [`& fieldset`]: {
            borderRadius: 0
        },
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    ihFeatureFlagItem: {
        width: '100%',
        '& .MuiTypography-body1': {
            display: 'inline-flex',
            width: '100%'
        }
    },
    ihTextHighlight: {
        color: blue[600],
        fontWeight: 'bold',
        fontStyle: 'italic'
    }
}));

const IhFeatureFlagsControl = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [featureFlags, setFeatureFlags] = useState(
        ihFeatureFlagsData.reduce((obj, ffObj) => {
            obj[ffObj.name] = false;

            return obj;
        }, {})
    );

    useEffect(() => {
        setIsLoading(false);
        let ffs_data = Cookies.get('ih_ffs');
        if (ffs_data) {
            ffs_data = JSON.parse(ffs_data);
            setFeatureFlags({ ...featureFlags, ...ffs_data });
        }
    }, []);

    const handleSwitch = e => {
        const newFfData = {
            ...featureFlags,
            [e.target.name]: e.target.checked
        };
        // Store the feature flags in cookies
        Cookies.set('ih_ffs', JSON.stringify(newFfData), {
            secure: process.env.NODE_ENV !== 'development',
            sameSite: 'strict'
        });
        // Update the state
        setFeatureFlags(newFfData);
    };

    return (
        <Box className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} className={classes.ihUpcomingFeatGridItem}>
                    <Box className={classes.ihContainer}>
                        <Box mt={2} mb={3.125}>
                            <IhLineWithInnerText title="Settings" />
                        </Box>
                    </Box>
                    <Paper
                        className={`${classes.ihUpcomingFeatPaper} ${
                            classes.ihNoBorderRadius
                        } ${isLoading ? 'data-loading' : ''}`}
                    >
                        {isLoading ? (
                            <HowItWorksSkeleton />
                        ) : (
                            <FormControl component="fieldset">
                                <Typography
                                    variant="h6"
                                    className={classes.ihUpcomingFeatTitle}
                                >
                                    Upcoming | Unsupported Features
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    className={classes.ihUpcomingFeatIntro}
                                >
                                    You can enable the following
                                    upcoming/unsupported features,
                                    <span className={classes.ihTextHighlight}>
                                        {' '}
                                        refresh page{' '}
                                    </span>
                                    and explore. These features are not complete
                                    and may also have defects.
                                </Typography>
                                <FormGroup
                                    aria-label="position"
                                    row
                                    className={classes.ihUpcomingFeatRow}
                                >
                                    {ihFeatureFlagsData.map(
                                        ({ label, name }, i) => (
                                            <FormControlLabel
                                                key={i}
                                                control={
                                                    <Grid
                                                        component="label"
                                                        container
                                                        alignItems="center"
                                                    >
                                                        <Grid item>No</Grid>
                                                        <Grid item>
                                                            <Switch
                                                                color="primary"
                                                                name={name}
                                                                checked={
                                                                    featureFlags[
                                                                        name
                                                                    ]
                                                                }
                                                                onChange={
                                                                    handleSwitch
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>Yes</Grid>
                                                    </Grid>
                                                }
                                                label={`${i + 1}. ${label}`}
                                                labelPlacement="start"
                                                className={
                                                    classes.ihFeatureFlagItem
                                                }
                                            />
                                        )
                                    )}
                                </FormGroup>
                            </FormControl>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default IhFeatureFlagsControl;
