import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import './IhSkeletons.scss';

// MUI styles
const useStyles = makeStyles(theme => ({
    hiwSkeleton: {
        width: '100%',
        padding: theme.spacing(0),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2.5)
        }
    }
}));

export const HowItWorksSkeleton = props => {
    // Init the custom styles
    const classes = useStyles();

    return [...new Array(5)].fill(0).map((_, i) => (
        <Typography
            key={i}
            component="div"
            variant="h3"
            className={classes.hiwSkeleton}
        >
            <Skeleton animation="wave" />
        </Typography>
    ));
};
