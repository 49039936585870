import { userFemaleDP, userMaleDP, monjitDP } from './hcImgDataURI';
import patentsLogo from '../images/patents.png';
import hackathonLogo from '../images/hackathons.png';
import madJamLogo from '../images/mad-jam.png';
import goodPracticesLogo from '../images/good-practices.png';

export const envConfig = {
    hclcode: {
        ui: {
            url: `${window.location.protocol}//${window.location.hostname}:${
                window.location.port === '' ? 443 : 6765
            }/code`
        },
        api: {
            url: `${window.location.protocol}//${window.location.hostname}:${
                window.location.port === '' ? 443 : 5001
            }/codeapi`
        }
    },
    ihub: {
        ui: {
            url: `${window.location.protocol}//${window.location.hostname}:${
                window.location.port === '' ? 443 : 6766
            }`
        },
        api: {
            url: `${window.location.protocol}//${window.location.hostname}:${
                window.location.port === '' ? 443 : 5002
            }/api`
        }
    }
};

export const hcContributors = [
    {
        name: 'Renuka Sharma',
        dp: userFemaleDP,
        designation: 'Technical Specialist',
        email: 'renuka.sharma@hcl.com',
        role: 'UX/UI Designer',
        team: 'FrontPage Freebirds',
        about: 'I am a UX/UI Designer, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Renugadevi Palaniyapuramnatarajan',
        dp: '',
        designation: 'Senior Technical Lead',
        email: 'renugadevi.p@hcl.com',
        role: 'QA Engineer',
        team: 'Debug Entity',
        about: 'I am a QA Engineer, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Mohammed Aslam Vattaparambil',
        dp: userMaleDP,
        designation: 'Senior Developer',
        email: 'mohammedaslam.vatta@hcl.com',
        role: 'UI Developer',
        team: 'Explode Legacy',
        about: 'I am a Developer, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Monjit Nunisa',
        dp: '',
        designation: 'Lead Engineer',
        email: 'monjit.n@hcl.com',
        role: 'UI Developer',
        team: 'Binary Beasts',
        about: 'I am a Developer, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Karthick Chinnasamy',
        dp: '',
        designation: 'Senior Developer',
        email: 'karthick.chinnasamy@hcl.com',
        role: 'Fullstack Developer',
        team: 'Server Monks',
        about: 'I am a Fullstack Developer, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Srinivasulu Pasupuleti',
        dp: '',
        designation: 'Senior Technical Specialist',
        email: 'srinivasulu.pasup@hcl.com',
        role: 'Fullstack Developer',
        team: 'Sequel Extract',
        about: 'I am a Fullstack Developer, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Uma Prasad Malakapalli',
        dp: '',
        designation: 'Technical Specialist',
        email: 'umaprasad.malak@hcl.com',
        role: 'DX WAS Admin',
        team: 'Forbidden Linkers',
        about: 'I am a DX WAS Admin, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Ravi Nagendra Tirumalasetti',
        dp: '',
        designation: 'Technical Specialist',
        email: 'ravi.nagen@hcl.com',
        role: 'DX WCM Developer',
        team: 'Parallel Inertia',
        about: 'I am a DX WCM Developer, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Neetu Kumari',
        dp: '',
        designation: 'Member Technical Staff',
        email: 'neetu.k@hcl.com',
        role: 'Developer',
        team: 'Adobe Priests',
        about: 'I am a Developer, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Suresh HL',
        dp: '',
        designation: 'Senior Technical Specialist',
        email: 'suresh.h@hcl.com',
        role: 'Developer',
        team: 'Acrobat Aztecs',
        about: 'I am a Developer, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Karthikeyan Dakshinamurthy',
        dp: '',
        designation: 'Enterprise Architect',
        email: 'karthid@hcl.com',
        role: 'Enterprise Architect',
        team: 'Port Manifest',
        about: 'I am a Enterprise Architect, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Sachi Mangala',
        dp: '',
        designation: 'Global Director',
        email: 'sachidanand.m@hcl.com',
        role: 'Developer',
        team: 'Light Wave Empire',
        about: 'I am a Developer, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    }
];

const sortArrayOfObjects = arr =>
    (arr &&
        arr
            .map(obj => obj.value || obj.name)
            .sort()
            .reduce(
                (resArr, str) => [
                    ...resArr,
                    { value: str, label: str, name: str }
                ],
                []
            )) ||
    [];

const hcSponsorsData = [
    {
        value: 'Darren Oberst',
        label: 'Darren Oberst'
    },
    {
        value: 'Rajesh Iyer',
        label: 'Rajesh Iyer'
    },
    {
        value: 'Madhurima Chandra',
        label: 'Madhurima Chandra'
    },
    {
        value: 'Richard Jefts',
        label: 'Richard Jefts'
    },
    {
        value: 'Doug Snadecki',
        label: 'Doug Snadecki'
    },
    {
        value: 'Luca Landi',
        label: 'Luca Landi'
    },
    {
        value: 'Brian Muskoff',
        label: 'Brian Muskoff'
    }
];

export const hcSponsors = sortArrayOfObjects(hcSponsorsData);

const hcProductCategoriesData = [
    {
        value: 'Innovations',
        label: 'Innovations'
    },
    {
        value: 'Automation',
        label: 'Automation'
    },
    {
        value: 'Commerce & Marketing',
        label: 'Commerce & Marketing'
    },
    {
        value: 'Data Management',
        label: 'Data Management'
    },
    {
        value: 'Secure DevOps',
        label: 'Secure DevOps'
    },
    {
        value: 'Digital Solutions',
        label: 'Digital Solutions'
    },
    {
        value: 'Mainframes',
        label: 'Mainframes'
    }
];

export const hcProductCategories = sortArrayOfObjects(hcProductCategoriesData);

const hcTechStackData = [
    {
        value: 'Voice Search',
        label: 'Voice Search'
    },
    {
        value: 'WebAssembly',
        label: 'WebAssembly'
    },
    {
        value: 'Machine Learning',
        label: 'Machine Learning'
    },
    {
        value: 'Artificial Intelligence',
        label: 'Artificial Intelligence'
    },
    {
        value: 'Data Security',
        label: 'Data Security'
    },
    {
        value: 'Progressive Web Apps',
        label: 'Progressive Web Apps'
    },
    {
        value: 'Multi-experience',
        label: 'Multi-experience'
    },
    {
        value: 'Motion UI',
        label: 'Motion UI'
    },
    {
        value: 'Micro Frontends',
        label: 'Micro Frontends'
    },
    {
        value: 'React',
        label: 'React'
    },
    {
        value: 'Vue.js',
        label: 'Vue.js'
    },
    {
        value: 'Flutter',
        label: 'Flutter'
    },
    {
        value: 'Angular',
        label: 'Angular'
    },
    {
        value: 'Node.js',
        label: 'Node.js'
    },
    {
        value: 'Django',
        label: 'Django'
    },
    {
        value: 'Laravel',
        label: 'Laravel'
    },
    {
        value: 'Ruby on Rails',
        label: 'Ruby on Rails'
    },
    {
        value: 'Ionic',
        label: 'Ionic'
    },
    {
        value: 'Phonegap/Cordova',
        label: 'Phonegap/Cordova'
    },
    {
        value: 'Wordpress',
        label: 'Wordpress'
    },
    {
        value: 'Drupal',
        label: 'Drupal'
    },
    {
        value: 'HCL DX',
        label: 'HCL DX'
    },
    {
        value: '.NET',
        label: '.NET'
    },
    {
        value: 'Material Design',
        label: 'Material Design'
    },
    {
        value: 'Bootstrap',
        label: 'Bootstrap'
    },
    {
        value: 'JavaScript',
        label: 'JavaScript'
    },
    {
        value: 'Java',
        label: 'Java'
    },
    {
        value: 'Go',
        label: 'Go'
    },
    {
        value: 'Python',
        label: 'Python'
    },
    {
        value: 'Swift',
        label: 'Swift'
    },
    {
        value: 'MongoDB',
        label: 'MongoDB'
    },
    {
        value: 'Redis',
        label: 'Redis'
    },
    {
        value: 'PostgreSQL',
        label: 'PostgreSQL'
    },
    {
        value: 'Oracle',
        label: 'Oracle'
    },
    {
        value: 'SQL Server',
        label: 'SQL Server'
    },
    {
        value: 'Informix',
        label: 'Informix'
    },
    {
        value: 'HTML',
        label: 'HTML'
    },
    {
        value: 'CSS',
        label: 'CSS'
    },
    {
        value: 'SASS',
        label: 'SASS'
    },
    {
        value: 'HTTP',
        label: 'HTTP'
    },
    {
        value: 'DDP',
        label: 'DDP'
    },
    {
        value: 'REST',
        label: 'REST'
    },
    {
        value: 'JSON',
        label: 'JSON'
    },
    {
        value: 'XML',
        label: 'XML'
    },
    {
        value: 'CSV',
        label: 'CSV'
    },
    {
        value: 'Ansible',
        label: 'Ansible'
    },
    {
        value: 'YAML',
        label: 'YAML'
    },
    {
        value: 'IoC',
        label: 'IoC'
    },
    {
        value: 'VS Code',
        label: 'VS Code'
    },
    {
        value: 'TypeScript',
        label: 'TypeScript'
    },
    {
        value: 'Eclipse',
        label: 'Eclipse'
    },
    {
        value: 'UML',
        label: 'UML'
    },
    {
        value: 'Cloud Native',
        label: 'Cloud Native'
    },
    {
        value: 'z/OS',
        label: 'z/OS'
    },
    {
        value: 'Prometheus',
        label: 'Prometheus'
    },
    {
        value: 'Grafana',
        label: 'Grafana'
    },
    {
        value: 'Mobile',
        label: 'Mobile'
    }
];

export const hcTechStack = sortArrayOfObjects(hcTechStackData);

export const hcTechColors = [
    {
        color: '#641E16'
    },
    {
        color: '#17202A'
    },
    {
        color: '#145A32'
    },
    {
        color: '#78281F'
    },
    {
        color: '#186A3B'
    },
    {
        color: '#7B241C'
    },
    {
        color: '#512E5F'
    },
    {
        color: '#424949'
    },
    {
        color: '#0B5345'
    },
    {
        color: '#512E5F'
    },
    {
        color: '#7D6608'
    },
    {
        color: '#4D5656'
    },
    {
        color: '#0E6251'
    },
    {
        color: '#4A235A'
    },
    {
        color: '#7E5109'
    },
    {
        color: '#D35400'
    },
    {
        color: '#154360'
    },
    {
        color: '#17A589'
    },
    {
        color: '#73C6B6'
    },
    {
        color: '#3498DB'
    },
    {
        color: '#AF7AC5'
    },
    {
        color: '#F1948A'
    },
    {
        color: '#73C6B6'
    },
    {
        color: '#CD6155'
    },
    {
        color: '#D35400'
    },
    {
        color: '#6C3483'
    },
    {
        color: '#138D75'
    },
    {
        color: '#AAB7B8'
    },
    {
        color: '#7DCEA0'
    },
    {
        color: '#AF601A'
    },
    {
        color: '#117864'
    },
    {
        color: '#76D7C4'
    },
    {
        color: '#5D6D7E'
    },
    {
        color: '#A9DFBF'
    },
    {
        color: '#FADBD8'
    },
    {
        color: '#A04000'
    },
    {
        color: '#6E2C00'
    },
    {
        color: '#B03A2E'
    },
    {
        color: '#AED6F1'
    },
    {
        color: '#2E4053'
    },
    {
        color: '#52BE80'
    },
    {
        color: '#E74C3C'
    },
    {
        color: '#1B2631'
    },
    {
        color: '#633974'
    },
    {
        color: '#117A65'
    }
];

export const getColourStyles = (
    chroma,
    tagBgColor = '#e0e0e0',
    delIconColor = '#00000040'
) => ({
    tag: {
        backgroundColor: tagBgColor,
        borderRadius: 0,
        position: 'relative',
        // Selected value text
        '& .MuiChip-label': {
            color:
                chroma.contrast(chroma(tagBgColor), '#fff') > 2
                    ? '#fff'
                    : '#000'
        },
        // Delete selected value icon
        '& .MuiChip-deleteIcon': {
            color: delIconColor
        },
        // Close, x, color
        '&::after': {
            backgroundColor:
                chroma.contrast(chroma(tagBgColor), '#fff') > 2
                    ? '#fff'
                    : '#000'
        },
        // Disabled contributor chip
        '&.MuiChip-root.Mui-disabled ': {
            opacity: '1'
        }
    },
    listbox: {
        padding: 1
    },
    option: {
        borderBottom: '1px solid #efebe9',
        // Hover
        '&[data-focus="true"]': {
            borderColor: '#f3e5f5'
        },
        // Selected
        '&[aria-selected="true"]': {
            borderColor: '#fff'
        }
    }
});

export const getCustomStyles = (componentName, chroma) => {
    switch (componentName) {
        case 'HcHome':
        case 'HcSolutions': {
            return {
                control: styles => ({
                    ...styles,
                    fontSize: '0.875rem',
                    borderRadius: '0',
                    backgroundColor: '#3498db08',
                    borderColor: '#3498db80',
                    cursor: 'pointer',
                    ':hover': {
                        ...styles[':hover'],
                        border: '1px solid #0066b3'
                    }
                }),
                option: (styles, { data, isDisabled, isSelected }) => ({
                    ...styles,
                    fontSize: '0.875rem',
                    cursor: 'pointer',
                    color:
                        !isSelected && data.color
                            ? data.color
                            : isSelected
                            ? '#fff'
                            : '#262626',
                    backgroundColor: isSelected
                        ? chroma('#17a589').alpha(0.8).css()
                        : '#fff',
                    ':hover': {
                        ...styles[':hover'],
                        color: '#262626',
                        backgroundColor:
                            !isDisabled && chroma('#17a589').alpha(0.2).css()
                    },
                    ':active': {
                        ...styles[':active'],
                        color: '#fff'
                    }
                }),
                singleValue: (styles, { data }) => ({
                    ...styles,
                    color: data.color
                        ? chroma('#0066b3').alpha(0.9).css()
                        : '#262626'
                })
            };
        }
        default: {
            // Default styling for any select components used in HCL Code
            return {
                control: styles => ({
                    ...styles,
                    fontSize: '0.875rem',
                    borderRadius: '0',
                    backgroundColor: '#3498db08',
                    borderColor: '#3498db80',
                    cursor: 'pointer',
                    ':hover': {
                        ...styles[':hover'],
                        border: '1px solid #0066b3'
                    }
                }),
                option: (styles, { data, isDisabled, isSelected }) => ({
                    ...styles,
                    fontSize: '0.875rem',
                    cursor: 'pointer',
                    color:
                        !isSelected && data.color
                            ? data.color
                            : isSelected
                            ? '#fff'
                            : '#262626',
                    backgroundColor: isSelected
                        ? chroma('#17a589').alpha(0.8).css()
                        : '#fff',
                    ':hover': {
                        ...styles[':hover'],
                        color: '#262626',
                        backgroundColor:
                            !isDisabled && chroma('#17a589').alpha(0.2).css()
                    },
                    ':active': {
                        ...styles[':active'],
                        color: '#fff'
                    }
                }),
                singleValue: (styles, { data }) => ({
                    ...styles,
                    color: data.color
                        ? chroma('#0066b3').alpha(0.9).css()
                        : '#262626'
                })
            };
        }
    }
};

// Returns a random integer between min (inclusive) and max (inclusive)
export const getRandomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

// Get min date as today and disable the past dates in calendar
// Format as YYYY-MM-DD
export const getDateAsToday = () => {
    const dateToday = new Date();
    const year = dateToday.getFullYear();
    let month = dateToday.getMonth() + 1;
    let day = dateToday.getDate();

    if (month < 10) {
        month = '0' + month.toString();
    }
    if (day < 10) {
        day = '0' + day.toString();
    }

    return `${year}-${month}-${day}`;
};

export const getFutureDateFromToday = (interval = 3) => {
    let dateToday = new Date();
    dateToday.setMonth(dateToday.getMonth() + interval);
    const dateByInterval = dateToday.toISOString().slice(0, 10);
    return dateByInterval;
};

export const createUTCDate = pDate => {
    let date = new Date(Date.parse(pDate));
    return new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    );
};

// Just to log formData values; keeping as handy tool (for dev only)
export const consoleFormData = formData => {
    for (let [key, value] of formData) {
        console.log('key =', key, ', value =', value);
    }
};

// HOLD_HC: Disabled for v1.0 release -- STARTS
// Custom Joyride styles
export const joyrideStyles = {
    buttonClose: {
        display: 'none'
    },
    buttonBack: {
        textTransform: 'uppercase'
    },
    buttonNext: {
        borderRadius: '0',
        outline: 'none',
        textTransform: 'uppercase'
    },
    spotlight: {
        borderRadius: '0',
        backgroundColor: '#ffffff7a'
    },
    tooltip: {
        borderRadius: '0'
    }
};

// Joyride customization specific to HCL Code app
export const joyrideConfig = {
    continuous: true,
    scrollToFirstStep: false,
    disableScrollParentFix: true,
    showProgress: true,
    showSkipButton: false,
    disableOverlayClose: true,
    disableCloseOnEsc: true,
    styles: joyrideStyles
};

export const hcHomeJsteps = [
    {
        disableBeacon: true,
        content: 'Challenges Filters',
        target: '.hc-home-filters-select',
        placement: 'bottom'
    },
    {
        content: 'Submit New Challenge',
        target: '.hc-submit-challenge-btn',
        placement: 'bottom'
    },
    {
        content: 'Challenges List',
        target: '.hc-scrollable-content',
        placement: 'bottom'
    },
    {
        content: 'A Challenge Item',
        target: '.hc-home-details',
        placement: 'bottom'
    },
    {
        content: 'Top Contributors List',
        target: '.hc-home-contributors .hc-scrollable-content',
        placement: 'bottom'
    },
    {
        content: 'A Contributors Item',
        target: '.hc-home-contributor',
        placement: 'bottom'
    }
];
// HOLD_HC: Disabled for v1.0 release -- ENDS

export const getSolutionsCount = (solution, type) =>
    solution
        ? solution.map(item => item.status === type).filter(Boolean).length
        : 0;

const hcDeliverablesData = [
    {
        value: 'Solution overview',
        label: 'Solution overview'
    },
    {
        value: 'Architecture diagram',
        label: 'Architecture diagram'
    },
    {
        value: 'Working prototype',
        label: 'Working prototype'
    },
    {
        value: 'Demo video',
        label: 'Demo video'
    },
    {
        value: 'Presentation',
        label: 'Presentation'
    },
    {
        value: 'Design',
        label: 'Design'
    },
    {
        value: 'Workflow diagram',
        label: 'Workflow diagram'
    },
    {
        value: 'API specification',
        label: 'API specification'
    },
    {
        value: 'Test data',
        label: 'Test data'
    },
    {
        value: 'Documentation',
        label: 'Documentation'
    }
];

export const hcDeliverables = sortArrayOfObjects(hcDeliverablesData);

const hcEvaluationCriteriaData = [
    {
        value: 'Innovation',
        label: 'Innovation'
    },
    {
        value: 'Completeness',
        label: 'Completeness'
    },
    {
        value: 'Commercial feasibility',
        label: 'Commercial feasibility'
    },
    {
        value: 'Process',
        label: 'Process'
    },
    {
        value: 'Technology',
        label: 'Technology'
    },
    {
        value: 'Usability',
        label: 'Usability'
    },
    {
        value: 'Performance',
        label: 'Performance'
    },
    {
        value: 'Automation',
        label: 'Automation'
    },
    {
        value: 'Quality',
        label: 'Quality'
    },
    {
        value: 'Extensibility',
        label: 'Extensibility'
    }
];

export const hcEvaluationCriteria = sortArrayOfObjects(
    hcEvaluationCriteriaData
);

export const hcChallengeTemplate = require('./hcl-software-coder-challenge-template.docx');
export const hcSolutionEvaluationTemplate = require('./hcl-software-coder-solution-evaluation-template.xlsx');

export const hcHowItWorks = [
    `Sponsors (L2 Heads or their delegates) can [post coding Challenges](/submit-challenge) by associating them with 500 to 2500 Innovation Points and providing a duration of 3 to 9 months to solve them. One Innovation Point is equivalent to USD 1. [Use this template](${hcChallengeTemplate}) to pre-fill and review the Challenge before submitting it.`,
    `Contributors can also [submit Challenge Proposals](/submit-challenge-proposal). Sponsors can [review these proposals](/submit-challenge?showChallengeProposals=true), and update and submit them as Challenges.`,
    'Contributors can [browse the Challenges](/) submitted by Sponsors, register to solve one of them, and work on it as a stretch project, individually or in a virtual team.',
    'Contributors can solve the Challenge as per the specified expectations, consider [patenting](https://microsite.hcltech.com/IPM/) the Solution, deliver source code securely in the designated GitHub location, and [submit the Solution](/submit-solution). The details of the unevaluated Solutions are visible only to their Contributors and all Sponsors.',
    `Sponsors can [evaluate the submitted Solutions](/solution) with an expert panel and select the Top Solutions. Awarded Innovation Points (and the corresponding cash rewards) accrue equally among all Contributors to the Top Solution. [Use this template](${hcSolutionEvaluationTemplate}) to capture Solution evaluation details before submitting them.`,
    'Sponsors can take forward the Top Solutions as per business requirements. [Contributors](/contributor) are listed along with their details on the Contributors page as per Innovation Points accrued'
];

const hcTeamMembersData = [
    {
        name: 'Sachi Mangala',
        dp: '',
        designation: 'Global Director',
        email: 'sachidanand.m@hcl.com',
        role: 'Product Owner',
        team: 'Light Wave Empire',
        about: 'I am a Developer, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Monjit Nunisa',
        dp: monjitDP,
        designation: 'Lead Engineer',
        email: 'monjit.n@hcl.com',
        role: 'UI Developer',
        team: 'Binary Beasts',
        about: 'I am a Developer, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Karthick Chinnasamy',
        dp: '',
        designation: 'Senior Developer',
        email: 'karthick.chinnasamy@hcl.com',
        role: 'Fullstack Developer',
        team: 'Server Monks',
        about: 'I am a Fullstack Developer, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Karthikeyan Dakshinamurthy',
        dp: '',
        designation: 'Enterprise Architect',
        email: 'karthid@hcl.com',
        role: 'Enterprise Architect',
        team: 'Port Manifest',
        about: 'I am a Enterprise Architect, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Renuka Sharma',
        dp: '',
        designation: 'Technical Specialist',
        email: 'renuka.sharma@hcl.com',
        role: 'UX/UI Designer',
        team: 'FrontPage Freebirds',
        about: 'I am a UX/UI Designer, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Renugadevi Palaniyapuramnatarajan',
        dp: '',
        designation: 'Senior Technical Lead',
        email: 'renugadevi.p@hcl.com',
        role: 'QA Engineer',
        team: 'Debug Entity',
        about: 'I am a QA Engineer, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Uma Prasad Malakapalli',
        dp: '',
        designation: 'Technical Specialist',
        email: 'umaprasad.malak@hcl.com',
        role: 'Admin',
        team: 'Forbidden Linkers',
        about: 'I am a DX WAS Admin, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    },
    {
        name: 'Arko Sen',
        dp: '',
        designation: 'Technical Lead',
        email: 'arko.sen@hcl.com',
        role: 'UX/UI Designer',
        team: 'FrontPage Freebirds',
        about: 'I am a UX/UI Designer, the expertise of computer scientists and computer engineers overlap in certain areas but computer science is a profession in its own right and one that attracts some of the world’s brightest minds.'
    }
];

const hcTeamMembersNameOnlyData = sortArrayOfObjects(hcTeamMembersData).map(
    obj => obj.name
);

export const hcTeamMembers = hcTeamMembersNameOnlyData.reduce(
    (result, name) => {
        for (let i = 0; i < hcTeamMembersData.length; i++) {
            if (name === hcTeamMembersData[i].name) {
                result.push(hcTeamMembersData[i]);
            }
        }

        return result;
    },
    []
);

export const hcSecDialogData = {
    title: 'Legal, Data Privacy & Information Security Notice',
    linkText: 'HCL TECHNOLOGIES Global Employee Data Privacy Notice',
    linkUrl:
        'https://hclo365.sharepoint.com/sites/GlobalPrivacyPortal/SiteAssets/asset/assets/PrivacyNotice.pdf',
    desc: `Users have no explicit or implicit expectation of privacy. 
            Any or all uses of HCL Innovation Hub and all content on
            HCL Innovation Hub may be intercepted, monitored, recorded,
            copied, audited, inspected, and disclosed to HCL
            TECHNOLOGIES LIMITED, the U.S. Government and law
            enforcement personnel as well as other authorized
            agencies and third party monitoring or auditing
            companies. HCL shall own all right, title and interest in
             and to any and all intellectual property developed or 
             disclosed in HCL Innovation Hub, including but not limited to 
             any patents, trademarks, trade secrets, ideas, developments, 
             feedback and contributions.  Your participation in HCL Innovation Hub 
             is voluntary and should not interfere with your daily work 
             responsibilities to HCL. By continuing to use HCL Innovation Hub you indicate
            your awareness of and consent to these terms and
            conditions of use. Do not use this HCL Innovation Hub if you do
            not agree to the terms and conditions stated in this
            notice.`,
    descCode: `Users have no explicit or implicit expectation of privacy. 
            Any or all uses of HCL Software Coder and all content on
            HCL Software Coder may be intercepted, monitored, recorded,
            copied, audited, inspected, and disclosed to HCL
            TECHNOLOGIES LIMITED, the U.S. Government and law
            enforcement personnel as well as other authorized
            agencies and third party monitoring or auditing
            companies. HCL shall own all right, title and interest in
             and to any and all intellectual property developed or 
             disclosed in HCL Software Coder, including but not limited to 
             any patents, trademarks, trade secrets, ideas, developments, 
             feedback and contributions.  Your participation in HCL Software Coder 
             is voluntary and should not interfere with your daily work 
             responsibilities to HCL. By continuing to use HCL Software Coder you indicate
            your awareness of and consent to these terms and
            conditions of use. Do not use this HCL Software Coder if you do
            not agree to the terms and conditions stated in this
            notice.`
};

export const hcSessionTimeoutData = {
    warning: {
        msg: 'Your session is about to expire. Do you want to continue the session?',
        btns: ['logout', 'continue']
    },
    expired: {
        msg: 'Your session is expired. Please login again.',
        btns: ['re-login']
    }
};

export const WARNING_INTERVAL = 7200000; // In msec, 2 * 60 * 60 * 1000 = 2hrs
export const EXPIRED_INTERVAL = 300; // In sec, 5 * 60 = 5mins

export const userWithIhubAccess = [
    'monjit.n',
    'sachidanand.m',
    'sachi.m',
    'jeevankumar_s',
    'jeevankumar'
];

export const gitHubOrgLink = {
    inScopeOrg: 'https://github01.hclpnp.com/in-scope-products/',
    notInScopeOrg: 'https://github01.hclpnp.com/not-in-scope-products/'
};

// iHub data
export const ihUserDetails = {
    name: 'Monjit Nunisa',
    email: 'monjit.n@hcl.com',
    dp: '',
    jobTitle: 'Lead Engineer',
    team: 'HCL Code',
    location: 'Jigani, Bangalore, India'
};

export const ihPrograms = [
    {
        name: 'InnerSource Project Portal',
        description:'Find and leverage reusable apps and products. Contribute to add or enhance product features.',
        linkUrl: 'https://innerportal.azurewebsites.net/#display=card',
        scores: [
            {
                title: 'Points Accrued',
                score: 130
            }
        ]
    },
    
    {
        name: 'HCL OneFAQ',
        description:
            'Internal Stack Overflow tool for technical Q&A.  Help build knowledge base. Currently, in soft launch mode.',
        linkUrl: 'https://onefaq.hcltechsw.com/',
        scores: [
            {
                title: 'Top Answers',
                score: 13
            },
            {
                title: 'Points Accrued',
                score: 130
            }
        ]
    },
    {
        name: 'Patents',
        description:
            'Patenting platform of HCL - Intellectual Property Monetization (IPM).',
        linkUrl: 'https://microsite.hcltech.com/IPM/own.html',
        scores: [
            {
                title: 'Patents Submitted',
                score: 5
            },
            {
                title: 'Patents Filed',
                score: 3
            },
            {
                title: 'Points Accrued',
                score: 110
            }
        ],
        logo: patentsLogo
    },
    {
        name: 'Open Source Project Office (OSPO)',
        description:
            'Support open source community engagement.  Access via GlobalProtect VPN.',
        linkUrl: 'https://github01.hclpnp.com/pages/hcl-labs-ospo/ospo-site/',
        scores: [
            {
                title: 'Projects Completed',
                score: 2
            },
            {
                title: 'Points Accrued',
                score: 20
            }
        ]
    },
    {
        name: 'Client Advocacy',
        description:
            'Build direct relationships with our customers. Hear their voice. Help them succeed with our products.',
        linkUrl: 'https://www.hcltechsw.com/resources/client-advocacy',
        scores: [
            {
                title: 'Client Advocacy Awards',
                score: 7
            },
            {
                title: 'Points Accrued',
                score: 70
            }
        ]
    },
    {
        name: 'Customer Idea Portal',
        description:
            'Great ideas can come from anywhere. Review ideas submitted and voted as top candidates by customers, to enhance our products.',
        linkUrl: 'https://www.hcltechsw.com/resources/submit-idea',
        scores: [
            {
                title: 'Points Accrued',
                score: 90
            }
        ]
    },
    {
        name: 'HCL Software Coder',
        description:
            'Solve coding challenges, earn innovation points, which can be converted to cash.',
        linkUrl: envConfig.hclcode.ui.url,
        scores: [
            {
                title: 'Solutions Submitted',
                score: 7
            },
            {
                title: 'Top Solutions',
                score: 3
            },
            {
                title: 'Points Accrued',
                score: 130
            }
        ],
        logo: require('../images/hcl-software-coder-vertical-logo-indigo.svg')
    },
    {
        name: 'HCL Software Hackathon',
        description:
            'Review Cloud Native Hackathon idea details in Jira.  Access via GlobalProtect VPN.',
        linkUrl: 'https://jira01.hclpnp.com/projects/CNH/summary',
        scores: [
            {
                title: 'Ideas Submitted',
                score: 5
            },
            {
                title: 'Top Ideas',
                score: 3
            },
            {
                title: 'Ideas Productized',
                score: 2
            },
            {
                title: 'Points Accrued',
                score: 170
            }
        ],
        logo: hackathonLogo
    },
    {
        name: 'MAD JAM',
        description:
            'HCL platform for ideapreneurs to seek to find the best solutions for the business challenges of tomorrow.',
        linkUrl: '#!',
        scores: [
            {
                title: 'Solutions Submitted',
                score: 5
            },
            {
                title: 'Winning Solutions',
                score: 2
            },
            {
                title: 'Points Accrued',
                score: 90
            }
        ],
        logo: madJamLogo
    },
    {
        name: 'Good Practices Conference',
        description: 'HCL event for sharing & celebrating best practices.',
        linkUrl: '#!',
        scores: [
            {
                title: 'Practices Submitted',
                score: 2
            },
            {
                title: 'Practices Awarded',
                score: 2
            },
            {
                title: 'Points Accrued',
                score: 60
            }
        ],
        logo: goodPracticesLogo
    },
    {
        name: 'Startup Labs',
        description:
            'Brief description of the program can be added here. Brief description of the program can be added here.',
        linkUrl: '#!',
        scores: [
            {
                title: 'Projects Completed',
                score: 3
            },
            {
                title: 'Points Accrued',
                score: 30
            }
        ]
    },
    {
        name: 'Campus Collaboration',
        description:
            'Brief description of the program can be added here. Brief description of the program can be added here.',
        linkUrl: '#!',
        scores: [
            {
                title: 'Projects Completed',
                score: 3
            },
            {
                title: 'Points Accrued',
                score: 30
            }
        ]
    },
    {
        name: 'Publications',
        description:
            'Brief description of the program can be added here. Brief description of the program can be added here.',
        linkUrl: '#!',
        scores: [
            {
                title: 'Social Media Content',
                score: 3
            },
            {
                title: 'Papers',
                score: 2
            },
            {
                title: 'Points Accrued',
                score: 70
            }
        ]
    }
];

export const ihCommunities = [
    {
        name: 'Architecture Board',
        description:
            'Review material from quarterly Architecture Board meetings.',
        linkUrl: 'https://hclo365.sharepoint.com/sites/ppaabshare',
        isUnderlined: true
    },
    {
        name: 'Tech Meetups',
        description:
            'Theme based sessions for technical knowledge sharing and learning by doing.',
        linkUrl:
            'https://hclo365.sharepoint.com/sites/HCLSWTD/SitePages/Tech-Meetups-@HCL-Software.aspx',
        isUnderlined: true
    },
    {
        name: 'Talent Development',
        description:
            'Find programs and resources to help you onboard, grow and lead.',
        linkUrl:
            'https://hclo365.sharepoint.com/sites/HCLSWTD/SitePages/LearningTeamHome.aspx',
        isUnderlined: true
    },
    {
        name: 'Cloud Native',
        description:
            'Join, learn & contribute in the community focused on Cloud Native.',
        linkUrl:
            'https://teams.microsoft.com/l/team/19%3ac4404f31fddd49728360a0a6d134d9b0%40thread.tacv2/conversations?groupId=4d6e8ca8-023f-4071-bc95-e1eab3a5ca9c&tenantId=189de737-c93a-4f5a-8b68-6f4ca9941912',
        isUnderlined: true
    },
    {
        name: 'Performance Engineering',
        description:
            ' Join, learn & contribute in the community focused on Performance Engineering domain across our products.',
        linkUrl:
            'https://teams.microsoft.com/l/team/19%3a2fb426a6b7044ccaa8443f976689501b%40thread.tacv2/conversations?groupId=227d6f8b-8bac-446c-97aa-106e6069930f&tenantId=189de737-c93a-4f5a-8b68-6f4ca9941912',
        isUnderlined: true
    },
    {
        name: 'UI',
        description:
            'Join, learn & contribute in the community focused on UI technologies.',
        linkUrl:
            'https://teams.microsoft.com/l/team/19%3a41274a35f8db450b864dede936e781bb%40thread.tacv2/conversations?groupId=4946587a-0b16-4e86-8d5c-9086a44187cb&tenantId=189de737-c93a-4f5a-8b68-6f4ca9941912',
        isUnderlined: true
    },
    {
        name: 'Machine Learning',
        description:
            'Join, learn & contribute in the community focused on Machine Learning.',
        linkUrl:
            'https://teams.microsoft.com/l/team/19%3a1d87ba874b0c44f1ada14951a975c53e%40thread.skype/conversations?groupId=7e51ff80-9587-4024-9fc2-d10aa1e260b7&tenantId=189de737-c93a-4f5a-8b68-6f4ca9941912',
        isUnderlined: true
    },
    {
        name: 'Conferences',
        description: 'Join, network & contribute to the community.',
        linkUrl: '#!',
        isUnderlined: true
    }
];

// Feature flags
export const hcFeatureFlagsData = [
    { label: 'Show Search box', name: 'search' }
];

export const ihFeatureFlagsData = [
    {
        label: 'Show upcoming Programs and Communities in Home page',
        name: 'upcomingFeatures'
    },
    { label: 'Show Dashboard menu', name: 'dashboardMenu' },
    { label: 'Show Search box', name: 'search' }
];
