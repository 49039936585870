// Check data type of a variable
export const checkDataType = data =>
    Object.prototype.toString.call(data).slice(8, -1);

// Form a display name of a user without dp. e.g.: John Doe = JD or Ram Kumar Gopal = RG
export const getDisplayName = fullName => {
    // fullName is `undefined`
    if (!fullName) {
        return '';
    }
    // fullName could be `Object` or `Array` which is invalid type
    const typeOfFullName = checkDataType(fullName);
    if (typeOfFullName === 'Object' || typeOfFullName === 'Array') {
        return '';
    }
    // Finally, fullName is `String`
    let displayName = '';
    const fullNameArr = fullName.split(' ');
    const fullNameArrLen = fullNameArr.length;

    displayName =
        fullNameArrLen > 1
            ? fullNameArr[0].charAt(0) +
              fullNameArr[fullNameArrLen - 1].charAt(0).toUpperCase()
            : fullNameArr[0].charAt(0).toUpperCase();

    return displayName;
};

// Logout and redirect to login page
export const logoutAndRedirect = (hclCodeApiUtils, Cookies) => {
    const pathname = encodeURI(window.location.pathname);
    const isHclCoderPath = pathname.includes('/code/');
    if (isHclCoderPath) {
        Cookies.remove('hc_acct');
        Cookies.remove('hc_reft');
        Cookies.remove('hc_secn_acpt');
        Cookies.remove('hc_sesst');
    } else {
        Cookies.remove('ih_acct');
        Cookies.remove('ih_reft');
        Cookies.remove('ih_secn_acpt');
    }

    // Redirect to logout URL to destroy the session
    window.location = `${hclCodeApiUtils.domain}/logout`;
};

// Check if the user is eligible to contribute source code through solutions for a particular challenge or not; CFIUS
export const isEligibleMember = (
    userDetails,
    developmentlocations,
    softwareproducts,
    selectedSofProduct
) => {
    if (
        userDetails &&
        developmentlocations &&
        developmentlocations.length &&
        softwareproducts &&
        softwareproducts.length &&
        selectedSofProduct
    ) {
        const isApprovedDevLoc = developmentlocations.findIndex(
            devLoc =>
                devLoc.country.toLowerCase() ===
                    userDetails.country.toLowerCase() && devLoc.isApproved
        );
        const isInScopeSofProd = softwareproducts.findIndex(
            sofProd =>
                sofProd.name.toLowerCase() ===
                    selectedSofProduct.toLowerCase() && sofProd.isInScope
        );
        if (
            (isInScopeSofProd > -1 && isApprovedDevLoc > -1) ||
            isInScopeSofProd === -1
        ) {
            return true;
        }
    }

    return false;
};

// Convert string to sentence case
export const toSentenceCase = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
