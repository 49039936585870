import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

// MUI styles
const useStyles = makeStyles(theme => ({
    ihLineWithText: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .MuiBox-root': {
            '&:nth-child(1), &:nth-child(3)': {
                flex: 1,
                borderBottom: '2px solid #0000003b'
            },
            '&:nth-child(2)': {
                margin: theme.spacing(0, 3),
                textAlign: 'center',
                fontSize: theme.spacing(5)
            }
        }
    }
}));

const IhLineWithInnerText = props => {
    const classes = useStyles();

    return (
        <Box className={classes.ihLineWithText}>
            <Box />
            <Box component="span">{props.title}</Box>
            <Box />
        </Box>
    );
};

IhLineWithInnerText.propTypes = {
    title: PropTypes.string.isRequired
};

export default IhLineWithInnerText;
