import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IhLineWithInnerText from '../IhLineWithInnerText';
import {
    ihPrograms,
    ihCommunities
} from '@hcl-code/hcl-code-data-sample/data/hcData';
import { IhContext } from '../../contexts/IhContext';

// MUI styles
const useStyles = makeStyles(theme => ({
    ihHomeContainer: {
        width: '70%',
        marginBottom: theme.spacing(4),
        [`& fieldset`]: {
            borderRadius: 0
        },
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    ihPaperLink: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: theme.spacing(30),
        padding: theme.spacing(3),
        '&:link, &:visited, &:active, &:focus': {
            outline: 'none',
            color: 'currentColor',
            textDecoration: 'none'
        }
    },
    mb3: {
        marginBottom: theme.spacing(3)
    },
    ihUnderlinedAppName: {
        textAlign: 'center',
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3)
    },
    ihUnderline: {
        display: 'inline-flex',
        height: theme.spacing(0.75),
        width: '100px',
        backgroundColor: '#acddf3',
        borderRadius: theme.spacing(2)
    },
    ihAppLogo: {
        height: theme.spacing(10),
        width: theme.spacing(31.5),
        '& img': {
            height: '100%',
            width: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(8),
            width: theme.spacing(22.5)
        },
        '& .MuiTypography-h5': {
            textAlign: 'center'
        }
    },
    ihAppName: {
        marginTop: theme.spacing(3)
    },
    ihCursor: {
        cursor: 'pointer'
    }
}));

const IhHome = props => {
    const classes = useStyles();
    const { ihFeatureFlags } = useContext(IhContext);

    const handleRedirect = url => () => {
        if (url && url !== '#!') {
            const appLink = document.createElement('a');
            appLink.href = url;
            appLink.target = '_blank';
            appLink.rel = 'noopener noreferrer';
            appLink.click();
        }
    };

    return (
        <Box className={classes.ihHomeContainer}>
            <Box mt={4} mb={8}>
                <IhLineWithInnerText title="Programs" />
            </Box>
            <Grid container spacing={6}>
                {ihPrograms.map(
                    ({ name, description, linkUrl, logo, isUnderlined }, i) =>
                        ihFeatureFlags &&
                        (ihFeatureFlags.upcomingFeatures ||
                            linkUrl !== '#!') && (
                            <Grid item xs={12} sm={4} md={4} key={i}>
                                <Paper
                                    className={`${classes.ihPaperLink} ${
                                        linkUrl && linkUrl !== '#!'
                                            ? classes.ihCursor
                                            : ''
                                    }`}
                                    onClick={handleRedirect(linkUrl)}
                                >
                                    {!logo ? (
                                        isUnderlined ? (
                                            <Box
                                                className={
                                                    classes.ihUnderlinedAppName
                                                }
                                            >
                                                <Typography variant="h5">
                                                    {name}
                                                </Typography>
                                                <Box
                                                    className={
                                                        classes.ihUnderline
                                                    }
                                                />
                                            </Box>
                                        ) : (
                                            <Box
                                                mb={3}
                                                className={classes.ihAppLogo}
                                            >
                                                <Typography
                                                    variant="h5"
                                                    className={
                                                        classes.ihAppName
                                                    }
                                                >
                                                    {name}
                                                </Typography>
                                            </Box>
                                        )
                                    ) : (
                                        <Box
                                            mb={3}
                                            className={classes.ihAppLogo}
                                        >
                                            <img src={logo} alt="App Logo" />
                                        </Box>
                                    )}
                                    <Typography
                                        variant="subtitle1"
                                        color="textPrimary"
                                    >
                                        {description}
                                    </Typography>
                                </Paper>
                            </Grid>
                        )
                )}
            </Grid>
            <Box mt={8} mb={8}>
                <IhLineWithInnerText title="Communities" />
            </Box>
            <Grid container spacing={6}>
                {ihCommunities.map(
                    ({ name, description, linkUrl, logo, isUnderlined }, i) =>
                        ihFeatureFlags &&
                        (ihFeatureFlags.upcomingFeatures ||
                            linkUrl !== '#!') && (
                            <Grid item xs={12} sm={4} md={4} key={i}>
                                <Paper
                                    className={`${classes.ihPaperLink} ${
                                        linkUrl && linkUrl !== '#!'
                                            ? classes.ihCursor
                                            : ''
                                    }`}
                                    onClick={handleRedirect(linkUrl)}
                                >
                                    {!logo ? (
                                        isUnderlined ? (
                                            <Box
                                                className={
                                                    classes.ihUnderlinedAppName
                                                }
                                            >
                                                <Typography variant="h5">
                                                    {name}
                                                </Typography>
                                                <Box
                                                    className={
                                                        classes.ihUnderline
                                                    }
                                                />
                                            </Box>
                                        ) : (
                                            <Typography
                                                variant="h5"
                                                className={classes.mb3}
                                            >
                                                {name}
                                            </Typography>
                                        )
                                    ) : (
                                        <Box mb={3}>
                                            <img src={logo} alt="App Logo" />
                                        </Box>
                                    )}
                                    <Typography
                                        variant="subtitle1"
                                        color="textPrimary"
                                    >
                                        {description}
                                    </Typography>
                                </Paper>
                            </Grid>
                        )
                )}
            </Grid>
        </Box>
    );
};

export default IhHome;
