import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import IhOurTeam from '../IhOurTeam';
import './IhFooter.scss';
import hclSwLogo from '@hcl-code/hcl-code-data-sample/images/HCLSoftware.png';
import hclLogo from '@hcl-code/hcl-code-data-sample/images/HCLTech.png';

const IhFooter = props => (
    <Fragment>
        <footer className="ih-footer">
            <ul className="ih-footer-nav">
                <li className="ih-footer-nav-item">
                    <a
                        href="https://www.hcltechsw.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={hclSwLogo} alt="HCL Software" />
                    </a>
                </li>
                <li className="ih-footer-nav-item">
                    <Box>Copyright &copy; 2020 HCL Technologies Limited</Box>
                </li>
                <li className="ih-footer-nav-item">
                    <a
                        href="https://www.hcltech.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={hclLogo} alt="HCL" />
                    </a>
                </li>
            </ul>
        </footer>
        <IhOurTeam />
    </Fragment>
);

export default IhFooter;
